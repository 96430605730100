/*
 * @Author: your name
 * @Date: 2020-09-03 11:22:18
 * @LastEditTime: 2020-09-04 18:21:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /isc-desktop-ui/src/store/module/mutations.js
 */
import { set } from '../../../utils/vuex';

export default {
    setSnackbar: set('snackbar'),
    setUser: set('user'),
    setDrawer: set('drawer'),
    setAppList: set('appList'),
    setToolbar: set('toolbar'),
    sidebar: set('sidebar'),
    commonConfig: set('commonConfig')
};
