/*
 * @Author: your name
 * @Date: 2020-09-02 16:40:17
 * @LastEditTime: 2020-09-08 10:29:26
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /isc-desktop-ui/src/utils/message.js
 */
'use strict';

import { Message, MessageBox, Loading } from '@isyscore/base-ui';

const stringify = (message) => (typeof message === 'object' ? message.message : String(message));

/**
 * 成功消息
 * @param message
 * @returns {ElMessageComponent}
 */
export const success = (message) => {
    return Message.success({
        duration: 5000,
        offset: 40,
        showClose: true,
        message: stringify(message)
    });
};

/**
 * 失败消息
 * @param message
 * @returns {ElMessageComponent}
 */
export const error = (message) => {
    return Message.error({
        duration: 5000,
        offset: 40,
        showClose: true,
        message: stringify(message)
    });
};

/**
 * 警告消息
 * @param message
 * @returns {ElMessageComponent}
 */
export const warning = (message) => {
    return Message.warning(stringify(message));
};

/**
 * 提醒消息
 * @param message {String | Error}
 * @param [options]
 * @returns {Promise<MessageBoxData>}
 */
export const alert = async (message, options) => {
    message = stringify(message);
    // 专业的错误信息不暴露给用户
    if (message.toString().includes('系统异常')) {
        message = '系统异常';
    }
    options = Object.assign(
        {
            title: '提醒',
            dangerouslyUseHTMLString: true,
            type: 'error'
        },
        options
    );
    try {
        await MessageBox.alert(message, options);
    } catch (e) {
        //
    }
};

/**
 * 确认消息
 * @param message {String | Error}
 * @param [options]
 * @returns {Promise<MessageBoxData>}
 */
export const confirm = (title, message, options) => {
    message = message || '';
    message = stringify(message);
    options = Object.assign(
        {
            title,
            dangerouslyUseHTMLString: true
        },
        options
    );
    return MessageBox.confirm(message, options);
};

/**
 * loading
 * @param [text="加载中"]
 * @returns {ElLoadingComponent}
 */
export const loading = (text = '加载中') => {
    return Loading.service({
        text
    });
};
