/*
 * @Author: your name
 * @Date: 2020-09-02 16:40:17
 * @LastEditTime: 2020-09-15 14:26:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /isc-desktop-ui/src/utils/axios.js
 */
'use strict';
import { app } from '@isyscore/messenger';
import Axios, { apiVersion, userToken, processFault } from '@isyscore/axios';
import store from '../store';
import { error } from './message';

const userTokenFeature = userToken({
    headerKey: 'token',
    tokenVal() {
        return store.state.sso ? store.state.sso.token : '';
    }
});

// const resDataFeature = resData();
// const standardFeature = standard();
const processFaultFeature = processFault({
    appMessenger: app,
    processor(fault) {
        const { message } = fault;

        if (fault.config && fault.config.dontHint) {
            return;
        }
        error(message);
    }
});

const axios = Axios.create();

const apiVersion2Feature = apiVersion({
    version: '2.0'
});

axios.features
    .use(apiVersion2Feature)
    .use(userTokenFeature)
    // .use(resDataFeature)
    // .use(standardFeature)
    .use(processFaultFeature);

export default axios;
