const routes = [
    {
        path: '/',
        redirect: { name: 'homepage' }
    },

    {
        path: '/homepage',
        name: 'homepage',
        component: () => import('@/views/home/index'),
        meta: { title: '终端桌面', keepAlive: true }
    },

    // {
    //     path: '/public-settings',
    //     name: 'common-settings',
    //     component: () => import('@/views/common-settings/index'),
    //     meta: { title: '公共设置' },
    //     redirect: { name: '基础配置' },
    //     children: [
    //         {
    //             path: 'basic-config',
    //             name: '基础配置',
    //             meta: { title: '基础配置' },
    //             component: () => import('@/views/common-settings/basic-config/index')
    //         },
    //         {
    //             path: 'email-config',
    //             name: '邮箱配置',
    //             meta: { title: '邮箱配置' },
    //             component: () => import('@/views/common-settings/email-config/index')
    //         },
    //         {
    //             path: 'sms-config',
    //             name: '短信配置',
    //             meta: { title: '短信配置' },
    //             component: () => import('@/views/common-settings/sms-config/index')
    //         },
    //         {
    //             path: 'weather-config',
    //             name: '天气服务',
    //             meta: { title: '天气服务' },
    //             component: () => import('@/views/common-settings/weather-config/index')
    //         },
    //         {
    //             path: 'lbs-config',
    //             name: '城市配置',
    //             meta: { title: '城市配置' },
    //             component: () => import('@/views/common-settings/lbs-config/index')
    //         },
    //         {
    //             path: 'msg-service',
    //             name: '消息服务',
    //             meta: { title: '消息服务' },
    //             component: () => import('@/views/common-settings/msg-service/index')
    //         }
    //     ]
    // },
    {
        path: '/new-app',
        name: 'new-app',
        component: () => import('@/views/app/index'),
        meta: { title: '应用' }
    },
    {
        path: '/light-app/:appCode',
        name: 'light-app',
        component: () => import('@/views/app/index'),
        meta: { title: '应用' }
    },
    {
        path: '/app/:appCode',
        name: 'app',
        component: () => import('@/views/app/index'),
        meta: { title: '三方应用' }
    },
    // TODO 正式上线干掉
    {
        path: '/local-app/:appCode',
        name: 'app',
        component: () => import('@/views/local-app/index'),
        meta: { title: '三方应用' }
    },
    {
        path: '/os/:appCode',
        name: 'app',
        component: () => import('@/views/app/index'),
        meta: { title: '内置应用' }
    }
];

if (process.env.NODE_ENV === 'development') {
    routes.push({
        path: '/debug',
        name: 'debug',
        component: () => import('@/views/debug/index'),
        meta: { title: 'debug' }
    });
}

routes.push({
    path: '*',
    redirect: { name: 'homepage' }
});

export default routes;
