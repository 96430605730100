import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/index';

import routes from './routes';

Vue.use(Router);

const router = new Router({
    base: process.env.BASE_URL,
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    let title = to.meta && to.meta.title;
    let appList = store.state.app.user.appList;
    let appCode = to.params.appCode;

    if (appCode && appList) {
        const found = appList.find((item) => item.code === appCode);
        if (found) title = found.name;
    }

    if (title) {
        document.title = title;
    }

    next();
});

export default router;
