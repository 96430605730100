/*
 * @Author: your name
 * @Date: 2020-09-03 11:57:14
 * @LastEditTime: 2020-09-04 17:41:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /isc-desktop-ui/src/store/module/index.js
 */
const requireModule = require.context('.', true, /\.js$/);
const modules = {};

requireModule.keys().forEach((fileName) => {
    if (fileName === './index.js') return;
    // Replace ./ and .js
    const path = fileName.replace(/(\.\/|\.js)/g, '');
    const [moduleName, imported] = path.split('/');

    if (!modules[moduleName]) {
        modules[moduleName] = {
            namespaced: true
        };
    }

    modules[moduleName][imported] = requireModule(fileName).default;
});

export default modules;
