/*
 * @Author: liqing
 * @Date: 2020-09-02 16:40:17
 * @LastEditTime: 2020-09-03 12:00:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /isc-desktop-ui/src/store/index.js
 */
import Vue from 'vue';
import Vuex from 'vuex';

// Store functionalit
import modules from './module';

Vue.use(Vuex);
const store = new Vuex.Store({
    modules
});

export default store;
