import axios from './utils/axios';
import store from './store';
import AppManager from '@/utils/app-manager';

const namespace = 'sso';
store.registerModule(namespace, {
    namespaced: true,

    state() {
        return {
            uuid: '',
            type: '',
            token: '',
            status: '',
            ticket: '',
            deptName: '',
            userName: '',
            telePhone: '',
            appInfoList: []
        };
    }
});

const preload = async () => {
    const sp = new URLSearchParams(location.search);
    const token = sp.get('token') || localStorage.getItem('ISC_SSO_TOKEN') || '';

    if (!token) return jumpLoginPage();

    // if (sessionStorage.getItem('is-reload') && sessionStorage.getItem('is-reload') === 'true') {
    //     // 这一段代码，防止刷新的时候url 地址不对
    //     let newUrl = delParam(location.href, ['appU', 'appT']);
    //     const state = { titLe: '', url: window.location.href };
    //     history.replaceState(state, '', newUrl);
    // } else {
    //     sessionStorage.setItem('is-reload', 'true');
    // }

    try {
        store.state[namespace].token = token;
        await getUserInfo(token);
        replaceState();
        return true;
    } catch (e) {
        jumpLoginPage();
    }
};

function delParam(url, toDeleteKeys) {
    let urlParam = window.location.search.substr(1); //页面参数
    let beforeUrl = url.substr(0, url.indexOf('?')); //页面主地址（参数之前地址）
    let nextUrl = '';

    let arr = new Array();
    if (urlParam != '') {
        let urlParamArr = urlParam.split('&'); //将参数按照&符分成数组
        for (let i = 0; i < urlParamArr.length; i++) {
            let paramArr = urlParamArr[i].split('='); //将参数键，值拆开
            if (toDeleteKeys.indexOf(paramArr[0]) === -1) {
                arr.push(urlParamArr[i]);
            }
        }
    }
    if (arr.length > 0) {
        nextUrl = '?' + arr.join('&');
    }
    url = beforeUrl + nextUrl;
    return url;
}

const getUserInfo = async (token) => {
    const data = await axios.get('/api/permission/auth/status');
    let appInfoList;
    try {
        appInfoList = await axios.get('/api/ops/config/user/desktop/getMenuList');
    } catch (error) {
        if (error.code === 401) {
            jumpLoginPage();
            return;
        }

        appInfoList = [];
    }
    const { uuid, type, status, ticket, deptName, nickname, loginName, telePhone } = data;
    const { state } = store;
    // state[namespace].uuid = uuid;
    // state[namespace].type = type;
    state[namespace].token = data.token;
    // state[namespace].status = status;
    // state[namespace].ticket = ticket;
    state[namespace].deptName = deptName;
    state[namespace].userName = nickname || loginName;
    state[namespace].telePhone = telePhone;
    state[namespace].appInfoList = appInfoList;
    state.app.user = {
        // uuid,
        telePhone,
        token,
        name: nickname || loginName,
        appList: appInfoList.filter((item) => item.inMenu === 1)
    };
    let appManager = AppManager.getInstance();
    appManager.setUserAppList(appInfoList);
    state.app.commonConfig = true;
    state.app.sysManage = appInfoList.findIndex((item) => item.code === 'sysmanage') !== -1;
    return data;
};

const jumpLoginPage = () => {
    const reten = encodeURIComponent(location.origin + location.pathname);
    location.assign(`${ENVS.ssoUrl}?return=${reten}`);
};

// 去掉url中的参数信息
const replaceState = () => {
    const sp = new URLSearchParams(location.search);
    sp.delete('token');
    const qs = sp.toString();
    const search = qs ? '?' + qs : '';
    const href = location.origin + location.pathname + search + location.hash;
    history.replaceState(null, document.title, href);
};

export default preload;
