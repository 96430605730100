import { urlType } from '@/common/dict';

export default [
    // code 一定要是 local- 真实appCode
    // TODO 正式环境干掉
    // {
    //     code: 'local-permission',
    //     name: '本地权限',
    //     addr: 'http://localhost:8081/permission',
    //     logo: '/desktop/icon/device-manage.png',
    //     type: urlType.LOCAL_APP,
    //     surName: 'B'
    // },
    // {
    //     code: 'local-apix',
    //     name: '本地APIX',
    //     addr: 'http://localhost:8080/os/apix/',
    //     logo: '/desktop/icon/device-manage.png',
    //     type: urlType.LOCAL_APP,
    //     surName: 'B'
    // }
];
