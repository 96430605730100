export const urlType = Object.freeze({
    THIRD_PARTY: 1,
    LOCAL_APP: 99
});

export const APP_TYPE = Object.freeze({
    DEFAULT_APP: 0,
    THIRD_PARTY_APP: 3,
    EXTERNAL_LINK_APP: 5,
    LIGHT_APP: 6,
    LOCAL_TEST_APP: 99 // 本地测试
});
