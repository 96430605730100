import router from '../router';
import { bus } from '@/bus/bus.js';
import store from '@/store';
import { pathJoin } from '@isyscore/utils/path';
import { qsParse } from '@isyscore/utils/qs';
import { desk } from '@isyscore/messenger';
import localApps from '@/common/link-apps';
import { urlType, APP_TYPE } from '@/common/dict';
import { error } from './message';

export default class AppManager {
    constructor() {
        this.userAppList = [];
        this.openedApps = [];
        this.currentAppCode = '';
        this.instance = null;
        this.recoverFromReload();
        this.watchRoute();
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new AppManager();
        }
        return this.instance;
    }

    // 塞一下可以访问的 APP 信息
    // TODO 改造下直接用 store 里边的数据
    setUserAppList(arr) {
        try {
            // TODO 正式环境干掉
            if (process.env.NODE_ENV === 'development') {
                this.userAppList = JSON.parse(JSON.stringify(arr)).concat(localApps);
            } else {
                this.userAppList = JSON.parse(JSON.stringify(arr));
            }
            this.userAppList = this.userAppList.map((item) => {
                return Object.freeze({
                    code: item.code,
                    url: item.addr || item.redirectUrl || '', // 兼容一下本地调试
                    name: item.name,
                    type: item.type,
                    outsideAppTag: item.type === 5,
                    desktopUrl: '',
                    loaded: false,
                    redirectUrl: item.redirectUrl || ''
                });
            });

            if (this.testJumpFromOutSide()) {
                this.setOpenAppsFromQuery();
            }
        } catch (err) {
            console.error(err);
            throw new Error('获取用户授权的应用列表失败');
        }
    }

    // 刷新页面，重新加载
    recover() {
        if (desk.sessionStorage.getItem('openedApps')) {
            this.openedApps = JSON.parse(desk.sessionStorage.getItem('openedApps'));
        }

        this.setCurrentApp(desk.sessionStorage.getItem('currentApp') || '');

        if (desk.sessionStorage.getItem('currentApp')) {
            const currentApp = this.openedApps.filter(
                (item) => desk.sessionStorage.getItem('currentApp') === item.code
            );
            if (currentApp[0].type === 5) {
                window.open(currentApp[0].url, '_blank');
            }
        }
    }

    // 刷新页面，重新加载
    recoverFromReload() {
        const sp = new URLSearchParams(location.search);
        if (sp.get('token')) {
            return;
        }

        this.recoverOpenApps();
        this.recoverCurrentApp();
        this.recoverOutsideWindow();
    }

    recoverOpenApps() {
        if (!desk.sessionStorage.getItem('openedApps')) {
            return;
        }
        let cacheApps = JSON.parse(desk.sessionStorage.getItem('openedApps'));

        const hasCurrentApp = cacheApps.some((item) => getAppCodeFromLocation() === item.code);

        if (!hasCurrentApp) {
            return (this.openedApps = cacheApps);
        }

        const currentApp = cacheApps.find((item) => getAppCodeFromLocation() === item.code);
        currentApp.desktopUrl = window.location.href;
        currentApp.url = getAppUrlFromLocation(currentApp);

        this.setOpenedApps(cacheApps);

        function getAppCodeFromLocation() {
            const pathParts = location.pathname.split('/');
            if (!pathParts.length) return '';
            return pathParts[pathParts.length - 1];
        }

        function getAppUrlFromLocation(app) {
            if (app.type === APP_TYPE.LIGHT_APP) {
                return app.redirectUrl;
            }
            const appCode = getAppCodeFromLocation();

            const prefixMap = {
                [APP_TYPE.THIRD_PARTY_APP]: '/app/',
                [APP_TYPE.DEFAULT_APP]: '/os/'
            };

            let prefix = prefixMap[app.type];
            if (!prefix) prefix = '/';

            let url = prefix + app.code;

            const qsObj = qsParse(location.search);
            if (!qsObj.appU || !qsObj.appT) {
                return url;
            }
            if (qsObj.appT === 'history') {
                url += qsObj.appU;
            } else {
                url += `#${qsObj.appU}`;
            }
            return url;
        }
    }

    recoverOutsideWindow() {
        const currentApp = this.openedApps.find((item) => desk.sessionStorage.getItem('currentApp') === item.code);

        if (!currentApp) return;

        if (currentApp.type === 5) {
            window.open(currentApp[0].url, '_blank');
        }
    }

    recoverCurrentApp() {
        if (!desk.sessionStorage.getItem('openedApps')) {
            return;
        }
        let cacheApps = JSON.parse(desk.sessionStorage.getItem('openedApps'));

        const hasCurrentApp = cacheApps.some((item) => getAppCodeFromLocation() === item.code);

        if (!hasCurrentApp) return;

        const currentApp = cacheApps.find((item) => getAppCodeFromLocation() === item.code);

        this.setCurrentApp(currentApp.code);

        function getAppCodeFromLocation() {
            const pathParts = location.pathname.split('/');
            if (!pathParts.length) return '';
            return pathParts[pathParts.length - 1];
        }
    }

    testJumpFromOutSide() {
        // queryObj.appU appT appN
        // this.openedApps.length === 0;
        const pathname = location.pathname;
        const index = pathname.lastIndexOf('/');
        if (index > -1) {
            const code = pathname.substring(index + 1, pathname.length);
            const isShow = this.userAppList.filter((item) => item.code === code);
            if (isShow.length === 0) return false;
            const openedAppList = this.openedApps.filter((item) => item.code === code);
            return openedAppList.length === 0;
        }

        return false;
    }

    setOpenAppsFromQuery() {
        // appN => appInfo
        // getUrl(appInfo)
        // set
        const pathname = location.pathname;
        const index = pathname.lastIndexOf('/');
        const code = pathname.substring(index + 1, pathname.length);
        let appInfo = Object.assign(
            {},
            this.userAppList.find((item) => item.code === code)
        );
        appInfo.url = this.getAppUrl(appInfo);
        this.openedApps.push(appInfo);
        this.setOpenedApps(this.openedApps);
        this.setCurrentApp(code);
    }

    // 监听路由变化，当跳转到 homepage 的时候，将 currentAPP 设置为 ''
    // 修复，打开过的应用，跳转回桌面，点击快捷方式无法打开的问题
    watchRoute() {
        const withAppRunnerRoutes = ['new-app', 'light-app', 'app'];
        router.afterEach((to, from) => {
            const isInAppRunnerPage = withAppRunnerRoutes.some((item) => item === to.name);
            if (isInAppRunnerPage) return;
            this.setCurrentApp('');
        });
    }

    openApp(appCode, to = '') {
        if (appCode === this.currentAppCode) {
            return;
        }
        if (!this.userAppList || !this.userAppList.length) {
            throw new Error('未获取到用户可访问 APP 列表');
        }
        this.clearUrlParams(); // 清除掉当前桌面 url 参数，为了新 app 计算

        const findApp = this.userAppList.find((app) => app.code === appCode);
        if (!findApp) {
            router.replace('/');
            return;
        }
        this.jumpToRoute(findApp);

        const isExisting = this.openedApps.some((item) => item.code === appCode);
        if (!isExisting) {
            this.addOpenedApp(appCode, to);
        }
        this.setCurrentApp(appCode);
    }

    jumpToRoute(app) {
        // const hasAppRunnerRoutes = ['app', 'new-app', 'light-app'];
        // const isInAppRunnerRouter = hasAppRunnerRoutes.some((item) => item === router.currentRoute.name);
        // if (isInAppRunnerRouter) return;
        let url = '';
        switch (app.type) {
            case APP_TYPE.THIRD_PARTY_APP:
                url = '/app/' + app.code;
                break;
            case APP_TYPE.DEFAULT_APP:
                url = '/os/' + app.code;
                break;
            case APP_TYPE.EXTERNAL_LINK_APP:
                url = `/new-app?redirectUrl=${app.redirectUrl}`;
                break;
            case APP_TYPE.LIGHT_APP:
                url = '/light-app/' + app.code;
                break;
            default:
                url = '/' + app.code;
        }

        // 本地测试类型
        if (app.type === 99) {
            url = `/local-app/${app.code}`;
        }
        if (app.type === APP_TYPE.EXTERNAL_LINK_APP) {
            window.open(`${app.redirectUrl}`, '_blank');
        }
        if (app.type === APP_TYPE.LIGHT_APP && !app.redirectUrl) {
            // 无限套娃的bug
            error('该轻应用未设置跳转路径');
            throw new Error('该轻应用未设置跳转路径');
        }
        router.push(url);
    }

    replaceApp(appCode) {
        if (!this.openedApps) {
            return;
        }
        if (!this.userAppList.some((item) => item.code === appCode)) {
            return;
        }
        let currentApp = this.openedApps.find((item) => item.code === this.currentAppCode);
        let newApp = this.userAppList.find((item) => item.code === appCode);
        for (let key in newApp) {
            if (currentApp[key] !== undefined) {
                currentApp[key] = newApp[key];
            }
        }
        currentApp.url = this.getAppUrl(newApp, { isReplace: true });
        currentApp.desktopUrl = '';
        this.setOpenedApps(this.openedApps);
        this.setCurrentApp(appCode);
    }

    appLoaded(appCode) {
        let loadedApp = this.openedApps.find((app) => app.code === appCode);
        loadedApp.loaded = true;
        this.setOpenedApps(this.openedApps);
    }

    // TODO 重构一下
    addOpenedApp(appCode, to = '') {
        const app = Object.assign(
            {},
            this.userAppList.find((item) => item.code === appCode)
        );
        app.url = this.getAppUrl(app, { to });
        app.desktopUrl = '';
        this.openedApps.push(app);
        this.setOpenedApps(this.openedApps);
        this.setCurrentApp(appCode);
    }

    setCurrentApp(appCode) {
        if (appCode === this.currentAppCode) {
            return;
        }
        this.currentAppCode = appCode;
        desk.sessionStorage.setItem('currentApp', appCode);
        this.send('current-app-change', appCode);
    }

    // TODO 挪出去
    changeTab(appCode) {
        const app = this.openedApps.find((item) => item.code === appCode);

        this.changeRouteWhenChangeTab(app);

        const state = { titLe: '', url: window.location.href };
        history.replaceState(state, '', app.desktopUrl);
        this.setCurrentApp(appCode);
    }

    changeRouteWhenChangeTab(app) {
        let url = app.type === APP_TYPE.THIRD_PARTY_APP ? '/app/' + app.code : '/os/' + app.code;

        // 本地测试
        if (app.type === APP_TYPE.LOCAL_TEST_APP) {
            url = `/local-app/${app.code}`;
        }

        if (router.currentRoute.path === url) return;
        router.push(url);
    }

    setOpenedApps(openedAppList) {
        this.openedApps = openedAppList;
        desk.sessionStorage.setItem('openedApps', JSON.stringify(this.openedApps));
        this.send('opened-app-change', this.openedApps);
    }

    closeApp(appCode) {
        if (!this.openedApps.some((item) => item.code === appCode)) {
            throw new Error('try to close an un-existing app');
        }
        if (this.openedApps.length === 1) {
            this.setCurrentApp('');
            this.setOpenedApps([]);
            this.goBackHome();
            return;
        }
        this.deleteOpenedApp(appCode);
    }

    deleteOpenedApp(appCode) {
        let index = this.openedApps.findIndex((item) => item.code === appCode);
        let newActiveApp = this.openedApps[index + 1] ? this.openedApps[index + 1] : this.openedApps[index - 1];
        this.openedApps.splice(index, 1);
        this.setOpenedApps(this.openedApps);
        this.changeTab(newActiveApp.code);
        // this.setCurrentApp(newActiveApp.code);
    }

    goBackHome() {
        router.push('/homepage');
    }

    getOpenedApps() {
        return this.openedApps;
    }

    // 内部路由变化，更改浏览器导航栏地址
    // TODO 搞个 LocationManager？
    updateUrl(queryObj) {
        let queryApp;
        if (process.env.NODE_ENV === 'development') {
            queryApp = 'local-' + queryObj.base.replaceAll('/', '').replaceAll('os', '');
        } else {
            queryApp = queryObj.base.replaceAll('/', '').replaceAll('os', '');
        }

        if (this.currentAppCode !== queryApp) {
            return;
        }
        let openedAppsClone = JSON.parse(JSON.stringify(this.openedApps));
        let currentAppClone = openedAppsClone.find((item) => item.code === this.currentAppCode);
        currentAppClone.url = this.getAppUrl(currentAppClone);
        const currentApp = this.openedApps.find((item) => item.code === this.currentAppCode);
        currentApp.desktopUrl = location.href;
        desk.sessionStorage.setItem('openedApps', JSON.stringify(openedAppsClone));
    }

    getAppUrl(appInfo, options = { isReplace: false, to: '' }) {
        let appPath =
            (appInfo.type === APP_TYPE.DEFAULT_APP
                ? '/os/' + appInfo.code
                : appInfo.type === APP_TYPE.THIRD_PARTY_APP
                ? '/app/' + appInfo.code
                : appInfo.code) + '/';

        if (appInfo.type === urlType.LOCAL_APP) {
            appPath = '/os/' + appPath.replace('local-', '');
            return this.computeUrl(appPath, options);
        }

        if (process.env.NODE_ENV === 'development') {
            return pathJoin(process.env.BASE_URL, '/app-local-demo.html');
        }

        if (appInfo.type === APP_TYPE.EXTERNAL_LINK_APP) {
            return appInfo.url;
        } else if (appInfo.type === APP_TYPE.LIGHT_APP) {
            return appInfo.redirectUrl;
        } else {
            return this.computeUrl(appPath, options);
        }
    }

    computeUrl(appPath, options = { isReplace: false, to: '' }) {
        if (options.to) return pathJoin(appPath, options.to);

        // TODO 这一大坨乱七八糟的逻辑
        try {
            const qsObj = qsParse(location.search);
            if (!qsObj.appU || !qsObj.appT || options.isReplace) {
                return `${appPath}`;
            } else {
                if (appPath.endsWith('/')) {
                    appPath = appPath.substring(0, appPath.length - 1);
                }
                if (qsObj.appT === 'history') {
                    return `${appPath}${qsObj.appU}`;
                } else {
                    return `${appPath}#${qsObj.appU}`;
                }
            }
        } catch (e) {
            console.error(e);
            return `${appPath}`;
        }
    }

    handleLocation(str) {
        let ret;
        ret = this.deleteToken(str);
        ret = this.deleteHash(ret);
        return ret;
    }

    deleteToken(str) {
        const index = str.indexOf('?token');
        if (index !== -1) {
            return str.substring(0, index);
        } else {
            return str;
        }
    }

    deleteHash(str) {
        const index = str.indexOf('#');
        if (index !== -1) {
            return str.substring(0, index);
        } else {
            return str;
        }
    }

    clearUrlParams() {
        const newUrl = location.origin + location.pathname + location.hash;
        const state = { titLe: '', url: window.location.href };
        history.replaceState(state, '', newUrl);
    }

    send(ev, args) {
        bus.$emit(ev, args);
    }

    on(ev, handler) {
        bus.$on(ev, handler);
    }

    off(ev, handler) {
        bus.$off(ev, handler);
    }
}
