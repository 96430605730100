/*
 * @Author: liqing
 * @Date: 2020-09-02 16:40:17
 * @LastEditTime: 2020-09-08 16:25:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /isc-desktop-ui/src/store/module/demo.js
 */
const debug = process.env.NODE_ENV !== 'production';

export default {
    snackbar: {
        color: 'Info.vue',
        show: false,
        text: 'This is info snackbar.'
    },
    strict: debug,
    user: {},
    drawer: false,
    toolbar: true,
    sidebar: false,
    loading: false,
    appList: [],
    commonConfig: true,
    sysManage: false
};
