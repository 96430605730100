import preload from './preload';

(async () => {
    const preloaded = await preload();

    if (!preloaded) {
        return;
    }

    import('./render');
    window.tempVersion = require('../package.json').version;
})();
